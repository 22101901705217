@import 'src/styles/variables';

.container {
  align-items: center;
  display: flex;
  padding-top: 260px - 50px;
  padding-bottom: 200px - 50px;
}

.headline {
  font-size: 50px;
  font-weight: 700;
  font-family: $cerebri;
  margin-bottom: 20px;
}

.subheadline {
  font-color: $darkblue;
  line-height: 1.5;
  font-weight: 300;
  max-width: 600px;
  margin-bottom: 20px;
}

.button {
  margin: 0;
}

.bottom {
  padding: 50px 10px;
  margin: 0 auto;
  text-align: center;

  @include for-size(desktop-up) {
    padding: 90px 10px 50px 10px;
  }

  @include for-size(tablet-portrait-up) {
    padding: 80px 10px 50px 10px;
  }
}

.action {
  margin: 0 auto;
  text-align: center;
  padding-top: 500px;

  @include for-size(tablet-portrait-up) {
    padding-top: 330px;
  }

  @include for-size(phone-only) {
    padding-top: 170px;
  }

  @include for-size(desktop-up) {
    padding-top: 500px;
  }
}

.image {
  margin-left: auto;
  width: 500px;
  height: 500px;
  opacity: 0.5;

  @include for-size(tablet-portrait-down) {
    display: none;
  }
}
